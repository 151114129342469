/** @format */

import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title';
import Icon from '@material-ui/core/Icon';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {boxLabelXml} from '../../components/labels';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddItem from './addItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {updateURLParams} from '../../utils/common';

const dymo = window.dymo;

const materials = [
  {name: 'PLA', v: 'PLA', showSize: true},
  {name: 'PETG', v: 'PETG', showSize: true},
  {name: 'PLA Ltd', v: 'PLA Ltd', showSize: true},
  {name: 'PLA Sub', v: 'PLA Sub', showSize: true},
  {name: 'Forge', v: 'FORGE', showSize: true},
  {name: 'T-Shirt', v: 'tshirt', showSize: false},
  {name: 'Raw', v: 'raw', showSize: false},
  {name: 'Poster', v: 'Poster', showSize: false},
  {name: 'Nozzles', v: 'nozzles', showSize: false},
  {name: 'Tubing', v: 'Tubing', showSize: false},
  {name: 'Parts', v: 'parts', showSize: false},
];

const showSize = materials
  .filter(m => {
    return m.showSize === true;
  })
  .map(m => m.v);

export default function Orders({history, printerName, addAlert}) {
  // const classes = useStyles();
  const location = useLocation();
  const {qs} = location;
  const updateUrl = updateURLParams(location, history);
  const [orders, setOrders] = useState([]);
  const [headers, setHeaders] = useState({});
  const [count, setCount] = useState(0);
  const [openItem, setOpenItem] = useState(false);
  const iPad = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleChangePage = (e, page) => {
    updateUrl({page});
  };

  const handleChangeRowsPerPage = e => {
    updateUrl({limit: e.target.value});
  };

  const checkIn = spoolId => () => {
    let url = `/inventory?tabId=1&spoolId=${spoolId}`;
    history.push(url);
  };

  const itemUse = itemId => () => {
    let url = `/inventory?tabId=2&itemId=${itemId}`;
    history.push(url);
  };

  const openAddItem = () => {
    setOpenItem(true);
  };

  const handleItemClose = () => {
    setOpenItem(false);
  };

  const printBinLabel = item => e => {
    e.preventDefault();

    if (!printerName) {
      addAlert('No printers found', 'error');
      return;
    }
    const numLabels = Number(prompt('How many labels would you like?', 1));
    const printParamsXml = dymo.label.framework.createLabelWriterPrintParamsXml(
      {copies: numLabels || 1}
    );
    var label = dymo.label.framework.openLabelXml(getLabelXml(item));
    label.print(printerName, printParamsXml);
  };

  const printBoxLabel = item => e => {
    e.preventDefault();
    if (!printerName) {
      addAlert('No printers found', 'error');
      return;
    }
    const numLabels = Number(prompt('How many labels would you like?', 1));
    const printParamsXml = dymo.label.framework.createLabelWriterPrintParamsXml(
      {copies: numLabels || 1}
    );
    var label = dymo.label.framework.openLabelXml(boxLabelXml(item));
    label.print(printerName, printParamsXml);
  };

  useEffect(() => {
    if (qs.page) {
      getInventory();
    } else {
      let defaults = {
        limit: 50,
        page: 0,
      };
      updateUrl(defaults);
    }
    // eslint-disable-next-line
  }, [qs, openItem]);

  const getInventory = () => {
    let query = new URLSearchParams();
    query.set('options.material', qs.material);
    if (qs.diameter) {
      query.set('options.diameter', qs.diameter);
      query.set('options.amount', qs.amount);
    }
    let url = `/api/items/?skip=${
      Number(qs.page) * Number(qs.limit)
    }&limit=${Number(qs.limit)}&all=true&q=${encodeURIComponent(
      query.toString()
    )}`;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (res) {
        return res.json();
      })
      .then(resp => {
        setOrders(resp);
        let headers_opts = {};
        resp.forEach(i => {
          Object.keys(i.options).forEach(key => {
            if (!headers_opts[key]) {
              headers_opts[key] = 1;
            }
          });
        });
        setHeaders(headers_opts);
        setCount(resp[0].totalCount);
      })
      .catch(e => {});
  };

  const getItems = e => {
    let opts = {};
    if (e.target.dataset) {
      opts[e.target.dataset.options] = e.target.value;
    } else {
      opts[e.target.name] = e.target.value;
    }
    if (!showSize.includes(opts.material || qs.material)) {
      opts.diameter = '';
      opts.amount = '';
    } else {
      if (!opts.diameter) {
        opts.diameter = '1.75mm';
      }
      if (!opts.amount) {
        opts.amount = '1kg';
      }
    }
    updateUrl(opts);
  };

  return (
    <React.Fragment>
      <Title>Items</Title>
      <div
        style={{
          alignContent: 'space-evenly',
          alignItems: 'center',
          display: 'flex',
          padding: '0px 10px 20px 10px',
          width: '100%',
        }}>
        <div style={{display: 'flex', flex: 1}}>
          <FormControl component="fieldset" style={{marginRight: '35px'}}>
            <FormLabel component="legend">Type</FormLabel>
            <Select
              labelId="type-select"
              id="type-select"
              value={qs.material}
              name="material"
              data-options="material"
              style={{minWidth: 150}}
              onChange={getItems}>
              {materials.map(m => {
                return (
                  <MenuItem key={m.name} value={m.v}>
                    {m.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div style={{display: 'flex'}}>
          {showSize.includes(qs.material) && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Diameter</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={qs.diameter}
                data-options="diameter"
                onChange={getItems}>
                <FormControlLabel
                  value="1.75mm"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'diameter'}}
                    />
                  }
                  label="1.75mm"
                  // labelPlacement="top"
                />
                <FormControlLabel
                  value="2.85mm"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'diameter'}}
                    />
                  }
                  label="2.85mm"
                  // labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          )}
          {showSize.includes(qs.material) && (
            <FormControl component="fieldset">
              <FormLabel component="legend">Amount</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={qs.amount}
                data-options="amount"
                onChange={getItems}>
                <FormControlLabel
                  value="5kg"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'amount'}}
                    />
                  }
                  label="5kg"
                  // labelPlacement="top"
                />
                <FormControlLabel
                  value="1kg"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'amount'}}
                    />
                  }
                  label="1kg"
                  // labelPlacement="top"
                />
                <FormControlLabel
                  value="200g"
                  control={
                    <Radio
                      color="primary"
                      inputProps={{'data-options': 'amount'}}
                    />
                  }
                  label="200g"
                  // labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          )}
        </div>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>qoh</TableCell>
            {!iPad &&
              headers &&
              Object.keys(headers).map(opt => {
                return <TableCell key={'option_' + opt}>{opt}</TableCell>;
              })}
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(row => (
            <TableRow
              key={row.id}
              style={{
                backgroundColor:
                  row.nsg === true || row.markSoldOut === true
                    ? 'lightgrey'
                    : 'white',
              }}>
              <TableCell>
                <Link to={`/item/${row.id}`}>
                  {row.imgSrc ? (
                    <img
                      src={row.imgSrc[0]}
                      style={{width: 50, maxHeight: 50}}
                      alt={row.name}
                    />
                  ) : row.options.material[0] === 'parts' ? (
                    row.name
                  ) : (
                    row.id.substr(-5)
                  )}
                </Link>
              </TableCell>
              <TableCell style={{maxWidth: 400}}>
                {row.options.material[0] === 'parts' ? (
                  <span dangerouslySetInnerHTML={{__html: row.desc}}></span>
                ) : (
                  row.name
                )}
              </TableCell>
              <TableCell>
                {row.qoh.toFixed(2).replace(/\.00$/, '')}{' '}
                {row.uom !== 'each' ? row.uom : ''}
              </TableCell>
              {!iPad &&
                Object.keys(headers).map(k => {
                  return (
                    <TableCell key={k} style={{maxWidth: 150}}>
                      {row.options[k] && row.options[k][0].toUpperCase()}
                    </TableCell>
                  );
                })}
              {/* <TableCell>${(row.price / 100).toFixed(2)}</TableCell> */}
              <TableCell align="right">
                {printerName && (
                  <>
                    <Button variant="outlined" onClick={printBinLabel(row)}>
                      <Icon
                        title="Print Bin Label"
                        className="fa fa-barcode"></Icon>
                    </Button>
                    &nbsp;
                    <Button variant="outlined" onClick={printBoxLabel(row)}>
                      <Icon
                        title="Print Box Label"
                        className="fa fa-cube"></Icon>
                    </Button>
                  </>
                )}
                &nbsp;
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={itemUse(row.id)}>
                  Use
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={checkIn(row.id)}>
                  Add
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow style={{borderBottom: '1px solid rgb(224, 224, 224)'}}>
            <TableCell>
              <span>
                <span style={{color: 'lightgrey'}}>◼</span> Not for Sale
              </span>
            </TableCell>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              colSpan={6}
              count={count}
              rowsPerPage={Number(qs.limit) || 0}
              page={Number(qs.page) || 0}
              SelectProps={{
                inputProps: {'aria-label': 'rows per page'},
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Fab
        color="primary"
        onClick={openAddItem}
        aria-label="add"
        style={{position: 'fixed', right: 10, bottom: 10}}>
        <AddIcon />
      </Fab>
      <AddItem
        openItem={openItem}
        addAlert={addAlert}
        template={orders[0]}
        handleItemClose={handleItemClose}
      />
    </React.Fragment>
  );
}

const getLabelXml = ({name, bin}) => {
  return `<?xml version="1.0" encoding="utf-8"?>
  <DieCutLabel Version="8.0" Units="twips" MediaType="Default">
    <PaperOrientation>Portrait</PaperOrientation>
    <Id>Small30334</Id>
    <PaperName>30334 2-1/4 in x 1-1/4 in</PaperName>
    <DrawCommands>
      <RoundRectangle X="0" Y="0" Width="3240" Height="1800" Rx="270" Ry="270"/>
    </DrawCommands>
    <ObjectInfo>
      <BarcodeObject>
        <Name>BARCODE</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="255" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <Text>${bin[0]}</Text>
        <Type>Code39</Type>
        <Size>Small</Size>
        <TextPosition>Bottom</TextPosition>
        <TextFont Family="Helvetica" Size="11" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <CheckSumFont Family="Helvetica" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
        <TextEmbedding>None</TextEmbedding>
        <ECLevel>0</ECLevel>
        <HorizontalAlignment>Center</HorizontalAlignment>
        <QuietZonesPadding Left="0" Right="0" Top="0" Bottom="0"/>
      </BarcodeObject>
      <Bounds X="244.8" Y="164.85" Width="2880" Height="1080"/>
    </ObjectInfo>
    <ObjectInfo>
      <TextObject>
        <Name>TEXT</Name>
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
        <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
        <LinkedObjectName></LinkedObjectName>
        <Rotation>Rotation0</Rotation>
        <IsMirrored>False</IsMirrored>
        <IsVariable>False</IsVariable>
        <HorizontalAlignment>Center</HorizontalAlignment>
        <VerticalAlignment>Bottom</VerticalAlignment>
        <TextFitMode>AlwaysFit</TextFitMode>
        <UseFullFontHeight>True</UseFullFontHeight>
        <Verticalized>False</Verticalized>
        <StyledText>
          <Element>
            <String>${name.toUpperCase().replace('&', '&amp;')}</String>
            <Attributes>
              <Font Family="Helvetica" Size="13" Bold="False" Italic="False" Underline="False" Strikeout="False"/>
              <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
            </Attributes>
          </Element>
        </StyledText>
      </TextObject>
      <Bounds X="244.8" Y="1353.6" Width="2880" Height="360"/>
    </ObjectInfo>
  </DieCutLabel>

`;
};
