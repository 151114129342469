/** @format */

import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import {makeStyles} from '@material-ui/core/styles';
import Title from '../../components/title';
import Grid from '@material-ui/core/Grid';
import NumPad from 'react-numpad-material';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '70%',
  },
  input: {
    height: 60,
  },
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    textAlign: 'left',
  },
  chips: {
    margin: theme.spacing(0.5),
    display: 'inline-flex',
  },
  item: {
    textAlign: 'left',
    '& ul': {
      paddingLeft: '1rem',
    },
  },
  gridRoot: {
    flexGrow: 1,
  },
}));

let checkingIn = false;
export default function CreateLabel(props) {
  const {location} = props;
  const {qs} = location;
  const classes = useStyles();
  const [id, setId] = useState(qs.itemId || '');
  const [qty, setQty] = useState('');
  const [reason, setReason] = useState('Production');
  const [uom, setUom] = useState('');
  const [item, setItem] = useState(undefined);

  useEffect(() => {
    if (!id) {
      setItem(undefined);
    } else {
      let url = `/api/item/${id}`;
      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(function (res) {
          return res.json();
        })
        .then(resp => {
          setItem(resp);
          setUom(resp.uom);
        })
        .catch(e => {});
    }
  }, [id]);

  const checkIn = e => {
    e.preventDefault();

    if (id && reason && qty && qty > 0 && checkingIn === false) {
      checkingIn = true;
      const data = {
        itemId: id,
        qty: qty * -1,
        // location: bin,
        UOM: uom,
        reason: reason,
      };

      let url = `/api/inventory_transactions`;
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(function (res) {
          return res.json();
        })
        .then(resp => {
          //open dialog
          // console.log(resp);
          props.addAlert(`${qty} items used.`, 'success');
          setReason('Production');
          setQty('');
          // document.getElementById('binInput').dataset['val'] = '';
          checkingIn = false;
        })
        .catch(e => {
          props.addAlert(e.message, 'error');
          checkingIn = false;
        });
    } else {
      props.addAlert('Your missing some info.', 'error');
    }
  };

  const getUOM = () => {
    let UOM = 'each';
    if (item) {
      UOM = item.uom;
    }
    let resp;
    switch (UOM) {
      case 'kg':
        resp = [
          <MenuItem value={'g'}>grams</MenuItem>,
          <MenuItem value={'kg'}>kilograms</MenuItem>,
        ];
        break;
      case 'sqft':
        resp = [
          <MenuItem value={'sqin'}>sqin</MenuItem>,
          <MenuItem value={'sqft'}>sqft</MenuItem>,
        ];
        break;
      case 'each':
        resp = <MenuItem value={'each'}>each</MenuItem>;
        break;
      default:
        resp = [
          <MenuItem value={'each'}>each</MenuItem>,
          <MenuItem value={'g'}>grams</MenuItem>,
          <MenuItem value={'kg'}>kilograms</MenuItem>,
        ];
        break;
    }
    return resp;
  };

  return (
    <>
      <Title color="secondary">Use Inventory</Title>
      <Grid
        container
        spacing={1}
        className={classes.gridRoot}
        alignItems="center"
        justify="center">
        <Grid container item xs={5} spacing={1}>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={checkIn}>
            <Input
              id="outlined-basic"
              label="Spool Id"
              onChange={e => {
                let tmp = e.target.value.split('/');
                tmp = tmp[tmp.length - 1];
                setId(tmp);
              }}
              className={classes.input}
              fullWidth={true}
              // onKeyDown={keyPress}
              value={id}
              tabIndex="0"
              placeholder="Spool Id"
              autoFocus={id ? false : true}
            />
            <br />
            <NumPad.Popover
              onChange={value => {
                let tmp = Number(value);
                let val = tmp === 0 || isNaN(tmp) ? '' : tmp;
                setQty(val);
                document.getElementById('reason').focus();
              }}
              position="centerRight"
              arrow="left"
              min={0}
              value={qty}>
              <Input
                id="outlined-basic"
                label="Qty"
                type="number"
                placeholder="Qty"
                value={qty}
                fullWidth={true}
                style={{height: 60}}
                // onKeyDown={keyPress}
                autoFocus={id ? true : false}
                tabIndex="1"
                onChange={e => {
                  let tmp = Number(e.target.value);
                  let val = tmp === 0 || isNaN(tmp) ? '' : tmp;
                  setQty(val);
                }}
              />
            </NumPad.Popover>
            <br />
            <br />
            <FormControl className={classes.formControl} fullWidth={true}>
              <InputLabel id="UOM_label">UOM</InputLabel>
              <Select
                labelId="UOM_label"
                id="UOM"
                value={uom}
                fullWidth={true}
                onChange={e => {
                  setUom(e.target.value);
                }}>
                {getUOM()}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl className={classes.formControl} fullWidth={true}>
              <InputLabel id="reason_label">Reason</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="reason"
                value={reason}
                fullWidth={true}
                onChange={e => {
                  setReason(e.target.value);
                }}>
                <MenuItem value={'Production'}>Production</MenuItem>
                <MenuItem value={'Damaged'}>Damaged</MenuItem>
                <MenuItem value={'Promo'}>Promo</MenuItem>
                <MenuItem value={'Shrinkage'}>Shrinkage</MenuItem>
                <MenuItem value={'Adjustment'}>Adjustment</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <Button type="submit" variant="contained" color="secondary">
              Use
            </Button>
          </form>
        </Grid>
        <Grid container item xs={5} spacing={1}>
          {item && (
            <div className={classes.item}>
              <h3>{item.name}</h3>
              <br />
              <img src={item.imgSrc[0] + '?h=100'} alt={item.name} />
              <ul>
                {Object.keys(item.tags).map(k => {
                  return (
                    <li key={k}>
                      {k}: {item.tags[k]}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
