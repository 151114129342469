/** @format */

import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export default function Production({zebraPrinter}) {
  const testPrint = () => {
    zebraPrinter.send(
      '~wc',
      function (success) {
        console.log('Sent to printer');
      },
      function (error) {
        console.error('Error:' + error);
      }
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <i className="fab fa-fedex fa-10x" />
        <p>
          Download and install Zebra Browserprint and connect your Zebra printer
          with 4x6 labels.
        </p>

        <p>
          <a href="/installers/zebra-browser-print-windows-v131445.zip">
            Windows Installer
          </a>
        </p>

        <p>
          <a href="/installers/zebra-browser-print-mac-v131445.zip">
            Mac Installer
          </a>
        </p>

        <p>
          <Button onClick={testPrint}>Test Print</Button>
        </p>
      </Grid>
      <Grid item xs={6}>
        <div style={{height: 140}}>
          <img
            src="/images/DYMO_logo.svg.png"
            alt="DYMO Logo"
            style={{width: 300}}
          />
        </div>

        <p>
          Download and install Dymo Label Software and connect your Dymo printer
          with 2.25x1.25 labels.
        </p>

        <p>
          <a href="/installers/DLS8Setup.8.5.4.exe">Windows Installer</a>
        </p>

        <p>
          <a href="/installers/DLS8Setup.8.7.5.dmg">Mac Installer</a>
        </p>
      </Grid>
      <div style={{position: 'fixed', bottom: 10, right: 10}}>
        Printbed Admin Version {process.env.REACT_APP_VERSION}
      </div>
    </Grid>
  );
}
