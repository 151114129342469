/** @format */

import React from 'react';
import {Link} from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
// import PrintIcon from '@material-ui/icons/Print'
import InventoryIcon from '@material-ui/icons/HomeWork';
import JobIcon from '@material-ui/icons/Work';
import CouponIcon from '@material-ui/icons/Redeem';
// import MaterialsIcon from '@material-ui/icons/ColorLens';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BlogIcon from '@material-ui/icons/Description';
import ChatIcon from '@material-ui/icons/Chat';
import DomainIcon from '@material-ui/icons/Domain';
import BuildIcon from '@material-ui/icons/Build';
import ShippingIcon from '@material-ui/icons/LocalShipping';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const JWT = cookies.get('JWT');

const HideMenu = process.env.REACT_APP_HIDE_MENU
  ? process.env.REACT_APP_HIDE_MENU.split(',')
  : [];
export const mainListItems = (
  <div style={{height: '100%'}}>
    <ListItem button component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={Link} to="/orders">
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItem>
    <ListItem button component={Link} to="/users">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItem>
    {!HideMenu.includes('production') && (
      <ListItem button component={Link} to="/production">
        <ListItemIcon>
          <DomainIcon />
        </ListItemIcon>
        <ListItemText primary="Production" />
      </ListItem>
    )}
    {!HideMenu.includes('inventory') && (
      <ListItem
        button
        component={Link}
        to="/inventory?material=PLA&diameter=1.75mm&amount=1kg">
        <ListItemIcon>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText primary="Inventory" />
      </ListItem>
    )}
    {!HideMenu.includes('schedule') && (
      <ListItem button component={Link} to="/schedule">
        <ListItemIcon>
          <CalendarIcon />
        </ListItemIcon>
        <ListItemText primary="Schedule" />
      </ListItem>
    )}
    <ListItem button component={Link} to="/shipping">
      <ListItemIcon>
        <ShippingIcon />
      </ListItemIcon>
      <ListItemText primary="Shipping" />
    </ListItem>
    {!HideMenu.includes('coupons') && (
      <ListItem button component={Link} to="/coupons">
        <ListItemIcon>
          <CouponIcon />
        </ListItemIcon>
        <ListItemText primary="Coupons" />
      </ListItem>
    )}
    <ListItem button component={Link} to="/hollidays">
      <ListItemIcon>
        <DateRangeIcon />
      </ListItemIcon>
      <ListItemText primary="Hollidays" />
    </ListItem>
    {!HideMenu.includes('chat') && (
      <ListItem button component={Link} to="/chat">
        <ListItemIcon>
          <ChatIcon />
        </ListItemIcon>
        <ListItemText primary="Chat" />
      </ListItem>
    )}
    {!HideMenu.includes('blog') && (
      <ListItem button component={Link} to="/blog">
        <ListItemIcon>
          <BlogIcon />
        </ListItemIcon>
        <ListItemText primary="Blog" />
      </ListItem>
    )}
    <ListItem button component={Link} to="/reporting">
      <ListItemIcon>
        <ShowChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reporting" />
    </ListItem>
    <ListItem button component={Link} to="/utils">
      <ListItemIcon>
        <BuildIcon />
      </ListItemIcon>
      <ListItemText primary="Utils" />
    </ListItem>
    {process.env.REACT_APP_SITE_NAME === 'Printbed' && (
      <ListItem
        style={{position: 'absolute', bottom: 0}}
        button
        component={'a'}
        href={`https://admin.jiggzly.com/jwt/${JWT}`}
        target="_blank"
        rel="noopener noreferrer">
        <ListItemIcon>
          <img src="/images/jiggzly.png" style={{width: '2rem'}} />
        </ListItemIcon>
        <ListItemText primary="Jiggzly" />
      </ListItem>
    )}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <JobIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
